import React from "react"
import { slugify } from "../util/utilityFunctions"
import { graphql, Link } from "gatsby"
import Slide from "react-reveal/Slide"
import GA from "../components/ga"
import Hero from "../components/hero"
import InnerWrapper from "../components/innerWrapper"
import Layout from "../components/layout"
import SEO from "../SEO"
import styles from "./scss/work.module.scss"


class work extends React.Component {
  render() {
    const { data } = this.props
    return (
      <>
        <GA />
        <Layout>
          <SEO
            title="Work"
            pathname="/work"
            description={data.contentfulSeo.workPageSeoDescription}
          />
          <Hero slogan={data.contentfulWorkPageHeroSlogan.slogan} cta={false} />
          <section className={styles.latestWork} id="work">
            <h2>Our Work</h2>
            <InnerWrapper width={1440}>
              <div className={styles.container}>
                <div className={styles.gallery}>
                  {data.allContentfulWork.edges.map((edge, index) => {
                    return (
                      <Slide left key={index}>
                        <Link to={`/work/${slugify(edge.node.title)}`}>
                          <div className={styles.text}>
                            {edge.node.image ? (
                              <img
                                className={styles.image}
                                src={edge.node.image.fixed.src}
                                alt={edge.node.image.title}
                              />
                            ) : (
                              ""
                            )}
                            <h4>{edge.node.title}</h4>
                            {/* <p>{edge.node.company}</p>
                        <a
                          href={edge.node.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Work
                        </a> */}
                          </div>
                        </Link>
                      </Slide>
                    )
                  })}
                </div>
                <div className={styles.more}>
                  <Link to="/#contact">
                    Get In Touch <span>→</span>
                  </Link>
                </div>
              </div>
            </InnerWrapper>
          </section>
        </Layout>

      </>
    )
  }
}

export const query = graphql`
  query WorkQuery {
    contentfulSeo {
      workPageSeoDescription
    }
    allContentfulWork(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          title
          company
          link
          image {
            title
            description
            fixed(width: 600, quality: 100) {
              src
            }
          }
        }
      }
    }
    contentfulWorkPageHeroSlogan {
      slogan
    }
  }
`

export default work
